<template>
    <div>
        <h1 class="mb-2 flex max-w-3xl flex-wrap gap-2 text-5xl font-semibold text-white">
            <slot name="title" />
            <span
                class="whitespace-normal bg-gradient-to-r from-green-400 to-teal-400 bg-clip-text text-transparent"
            >
                <slot name="title-colored" />
            </span>
        </h1>
        <p class="max-w-xl text-white">
            <slot></slot>
        </p>
    </div>
</template>
