<template>
    <div
        v-for="(sentence, index) in sentences"
        :key="index"
        class="container relative overflow-hidden text-2xl text-white md:text-5xl"
    >
        <ul
            class="container inline-block py-12"
            :style="`--start-position: ${(index + 25) * 2}%`"
        >
            <li class="inline-block">
                {{ sentence }}
            </li>
        </ul>
    </div>
</template>

<script setup>
    const sentences = [
        'Struggling to scale?',
        'Have a lot of unstructured information?',
        'Spending hours on answering common questions to your customers?',
        'Doing a lot of repetitive work?',
        'Creating deliverables to your clients?',
    ];
</script>

<style scoped>
    .animate-marquee {
        animation: marquee 30s linear infinite;
        --start-position: 0%;
    }
    .animate-marquee2 {
        animation: marquee 30s linear infinite;
        --start-position: 100%;
    }

    @keyframes marquee {
        0% {
            transform: translateX(var(--start-position));
        }
        100% {
            transform: translateX(-100%);
        }
    }
    @keyframes marquee2 {
        0% {
            transform: translateX(calc(100% - var(--start-position)));
        }
        100% {
            transform: translateX(0%);
        }
    }
</style>
