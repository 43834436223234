<script setup lang="ts">
    import { onMounted, onBeforeUnmount } from 'vue';
    // ToDo: Completely convert to Vue
    let intervalId: NodeJS.Timer | null = null;

    onMounted(() => {
        startWoosh();
    });

    onBeforeUnmount(() => {
        intervalId && clearInterval(intervalId);
    });

    // Every 200ms we'll generate a new "woosh" vertical line, place it randomly, and animate it.
    function startWoosh() {
        intervalId = setInterval(() => {
            let el = (
                (
                    document?.querySelector('#woosh') as HTMLTemplateElement
                )?.content.cloneNode(true) as HTMLDivElement
            ).firstElementChild as HTMLDivElement;
            document?.querySelector('#woosh')?.after(el);

            setTimeout(() => woosh(el), 10);
        }, 150);
    }

    function woosh(el: HTMLDivElement) {
        let random = (max: Number) => Math.floor(Math.random() * max);
        let parent = el;
        let child = el.firstElementChild as HTMLDivElement;

        parent.style.setProperty('left', random(100) + '%');
        parent.style.setProperty('top', random(1000) - 500 + 'px');
        parent.classList.remove('translate-y-0');
        setTimeout(() => parent.classList.add('translate-y-[500px]'), 50);

        child.classList.remove('opacity-0');
        setTimeout(() => {
            child.classList.add('opacity-0');

            setTimeout(() => {
                el.remove();
            }, 1000);
        }, 650);
    }
</script>

<template>
    <!-- Credits to livewire.laravel.com -->
    <div
        class="pointer-events-none absolute inset-0 h-[2400px] w-full overflow-hidden pb-64"
    >
        <template id="woosh">
            <div class="absolute translate-y-0 transition-transform duration-[1000ms]">
                <div
                    class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
                ></div>
            </div>
        </template>
        <div class="absolute translate-y-0 transition-transform duration-[1000ms]">
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 37%; top: 342px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#6a1f20aa] transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 8%; top: -475px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 50%; top: 192px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 47%; top: 451px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 85%; top: -335px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 33%; top: -2px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 79%; top: 47px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 66%; top: 157px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 75%; top: 379px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 69%; top: -221px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
        <div
            class="absolute translate-y-[500px] transition-transform duration-[1000ms]"
            style="left: 10%; top: 135px"
        >
            <div
                class="duration-350 h-[500px] w-[1px] bg-gradient-to-b from-transparent via-[#701d1daa] opacity-0 transition-opacity"
            ></div>
        </div>
    </div>
</template>
