<template>
    <section id="pricing" class="container min-h-screen items-center">
        <GeneralHeadline>
            <template #title>Exclusively for</template>
            <template #title-colored>the Go-Getters</template>
            Consulting packages depending for your scale and needs.
        </GeneralHeadline>

        <div
            class="grid grid-cols-1 gap-8 pt-16 text-white sm:grid-cols-2 lg:grid-cols-3"
        >
            <GeneralMeiliCard v-for="plan in pricing" class="flex flex-col">
                <div class="item flex flex-col items-start">
                    <h2 class="mb-4 text-2xl font-bold">{{ plan.name }}</h2>
                    <p class="font-semibold">{{ plan.price }}</p>

                    <a :href="plan.buttonLink">
                        <GeneralPrimaryButton class="mb-4 mt-8">
                            {{ plan.buttonText }}
                        </GeneralPrimaryButton>
                    </a>

                    <ul class="mt-12 list-inside space-y-2">
                        <li v-for="feature in plan.features" class="flex gap-1">
                            <span
                                v-html="tick"
                                class="fill-current text-green-400"
                            ></span>
                            {{ feature }}
                        </li>
                    </ul>
                </div>
            </GeneralMeiliCard>
        </div>

        <p>
            Prices are displayed excluding VAT. Reverse charge for companies
            registered in EU are available upon supplying valid EAN number.
        </p>
    </section>
</template>

<script setup lang="ts">
    const pricing = [
        {
            name: 'Raise awareness',
            price: '1500 EUR',
            features: ['For one month', 'One 1 hour long video call a week'],
            buttonText: 'Try me!',
            buttonLink: '',
        },
        {
            name: 'Make the change',
            price: '3000 EUR',
            features: [
                'For three months',
                'One 1 hour video call a week',
                'Support by email with 2 working-day reply',
            ],
            buttonText: 'Commit now!',
            buttonLink: '',
        },
        {
            name: 'Become the Hero',
            price: '5000 EUR',
            features: [
                'For three months',
                'Up to 2 hours long video call a week',
                'Support by email with 1 day reply',
                'Access to phone',
            ],
            buttonText: 'Transform now!',
            buttonLink: '',
        },
    ];

    const tick = ` <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="3"
            stroke="currentColor"
            class="h-5 w-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>`;
</script>
