<script setup>
    const logos = [
        { name: 'GPT 4 by OpenAI', src: 'openai' },
        { name: "Anthropic's Claude", src: 'anthropic' },
        { name: 'Llama 2 by Facebook', src: 'meta' },
        { name: 'Fyuy by ADEPT AI', src: 'some' },
        // https://www.adept.ai/blog/fuyu-8b
        { name: 'Mistral', src: 'mistral' },
    ];
</script>

<template>
    <section class="container flex min-h-[70vh] items-center py-12 text-white">
        <div class="w-full">
            <GeneralHeadline>
                <template #title>Hands-on experience with best</template>
                <template #title-colored>AI in the industry</template>
                We will pick the best model for your use case. The most popular
                model doesn't mean the best experience.
            </GeneralHeadline>

            <div
                class="my-16 flex flex-col items-center justify-between md:flex-row"
            >
                <nuxt-icon
                    v-for="logo in logos"
                    :key="logo.name"
                    :name="`brand/${logo.src}`"
                    :alt="logo.name"
                    :title="logo.name"
                    class="py-8 hover:animate-pulse"
                />
            </div>
        </div>
    </section>
</template>

<style scoped>
    .nuxt-icon svg {
        @apply h-8 w-full fill-current text-white hover:blur-[1px];
    }
</style>
