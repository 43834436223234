<template>
    <div
        class="pointer-events-none absolute inset-0 h-[2400px] w-full overflow-hidden pb-64"
    >
        <img
            style="
                position: absolute;
                width: 700px;
                height: 700px;
                right: 10%;
                top: 16px;
                animation-delay: 1s;
                animation-duration: 4.25s;
            "
            src="/blur.svg"
            class="animate-pulse-slow"
        />
        <img
            style="
                position: absolute;
                width: 700px;
                height: 700px;
                right: 25%;
                top: 520px;
            "
            src="/blur.svg"
            class="animate-pulse-slow"
        />
        <img
            style="
                position: absolute;
                width: 400px;
                height: 400px;
                left: 35%;
                top: 1156px;
                opacity: 0.6s;
            "
            src="/blur.svg"
            class="animate-pulse-slow"
        />
    </div>
</template>

<style>
    .animate-pulse-slow {
        animation: pulse 3s ease-in-out infinite;
    }
</style>
