<template>
    <section id="benefits" class="container text-white">
        <h1 class="mb-2 max-w-3xl text-5xl font-semibold">
            Seize the day
            <span
                class="bg-gradient-to-r from-green-400 to-teal-400 bg-clip-text text-transparent"
            >
                with AI
            </span>
        </h1>
        <p class="max-w-xl">
            My consulting covers several common areas that AI can help you
            deliver better experience and product to your customers. You will
            receive the guidance on how you or your development team can
            implement AI in your workflows.
        </p>
        <div class="grid grid-cols-1 gap-8 pt-16 sm:grid-cols-2 lg:grid-cols-3">
            <GeneralNuxtCard v-for="(card, index) in cards" :key="index">
                <h2 class="mb-4 text-xl font-semibold">{{ card.title }}</h2>
                <p class="">{{ card.description }}</p>
            </GeneralNuxtCard>
        </div>
    </section>
</template>

<script setup lang="ts">
    const cards = [
        {
            title: 'ChatGPT tailored to you',
            description:
                'ChatGPT-like experience using your own knowledge base and your own style of communication. No need to betray your brand.',
        },
        {
            title: 'Automated workflows',
            description:
                'Leverage our latest developments in vision, audio, textual generative AI to automate your most time consuming tasks and more.',
        },
        {
            title: 'Make recordings work for you',
            description:
                'Record your interviews, your meetings, your customer feedback and extract the information into structured bits of information.',
        },
        {
            title: 'GDPR-Friendly',
            description:
                'It has never been as easy to deploy on premises or on private cloud for handling sensitive and/or confidential client data.',
        },
        {
            title: 'On demand support',
            description:
                'A direct access for your questions and roadblocks that might arise during the modernization on email & phone.',
        },
        {
            title: '1-on-1',
            description:
                'Weekly video calls to learn about your case, scope the project and help to implement it from production to take off.',
        },
    ];

    // Ideas for icons:
    // 1. Revisit History: An icon of a clock or an hourglass, signifying time and history.
    // 2. Seamless Import: An icon of a data transfer arrow or an inbox tray, indicating importation.
    // 3. ChadAI: An icon of a brain or a humanoid, representing artificial intelligence or a personal assistant.
    // 4. Insightful Learnings: An icon of an eye or a light bulb, denoting insights and discovery.
    // 5. AI Feedback: An icon of a message bubble or a feedback loop, conveying the idea of receiving feedback or communication.
    // 6. Chrome Extension: An icon of the Chrome logo or a browser window, showing browser-based tool or extension.
</script>
