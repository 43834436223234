<script setup>
    function scrollTo() {
        // Calculate the height of the current element and scroll past it
        const elementHeight = window.innerHeight * 0.9;
        window.scrollBy({
            top: elementHeight,
            behavior: 'smooth',
        });
    }
</script>

<template>
    <section
        class="container relative mx-auto flex min-h-screen flex-col items-center justify-center"
    >
        <div class="mb-16 p-4">
            <h1
                class="gradient-headline text-5xl font-bold sm:text-6xl md:text-8xl"
            >
                Let
                <span class="underline decoration-slate-300">AI</span>
                double your revenue and productivity
            </h1>
            <p class="mt-4 text-xl text-white md:text-4xl">
                AI enables companies and entrepreneurial like yourself to skip
                the boilerplate work and focus on delivering the value to your
                customers!
            </p>
        </div>

        <div
            @click="scrollTo('#the-question-section')"
            class="absolute bottom-8 hidden flex-col items-center justify-center gap-4 text-gray-300 hover:cursor-pointer sm:flex"
        >
            <p class="">Scroll down to learn more</p>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="h-12 w-12 animate-bounce"
            >
                <path
                    fill-rule="evenodd"
                    d="M7.293 7.293a1 1 0 011.414 0L10 8.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                />
            </svg>
        </div>
    </section>
</template>

<style scoped>
    .gradient-headline {
        @apply bg-clip-text text-transparent;
        @apply bg-gradient-to-r from-pink-500 via-red-500 to-yellow-400 to-80%;
    }
</style>
