<script setup lang="ts">
    // https://github.com/lepikhinb/example-meilisearch-hover/blob/master/src/components/BaseCard.vue
    import { ref } from 'vue';
    import { useMouseInElement } from '@vueuse/core';

    const card = ref<HTMLDivElement>();
    const { elementX, elementY } = useMouseInElement(card);
</script>

<template>
    <div
        ref="card"
        :style="{
            '--x': `${elementX}px`,
            '--y': `${elementY}px`,
        }"
        class="border-gradient group relative overflow-hidden rounded-2xl border border-b-0 border-white/5 bg-[#201E22] bg-opacity-80 p-4 before:absolute before:-inset-px before:h-[calc(100%+2px)] before:w-[calc(100%+2px)] before:rounded-xl before:blur-xl lg:p-8"
    >
        <div class="relative">
            <slot />
        </div>
    </div>
</template>

<style scoped>
    .border-gradient::before {
        background: radial-gradient(
            350px circle at var(--x) var(--y),
            #179a4587 0%,
            transparent 100%
        );
    }
</style>
