<template>
    <section class="container">
        <div class="relative flex min-h-[80vh] items-center justify-center">
            <div
                class="animate-blur-box absolute h-64 w-full bg-blue-400 filter"
            ></div>
            <div
                class="absolute flex h-64 w-full items-center justify-center rounded-md bg-black text-center text-xl text-white"
            >
                <p class="text-center text-5xl font-semibold text-white">
                    What if you could
                    <span class="text-green-300"
                        >do this faster AND better?</span
                    >
                </p>
            </div>
        </div>
    </section>
</template>

<style scoped>
    @keyframes blur-box {
        from {
            filter: blur(10px);
        }

        to {
            filter: blur(20px);
        }
    }

    .animate-blur-box {
        animation: blur-box 2s alternate infinite;
    }
</style>
